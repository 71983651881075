<script>
    import { required, minLength } from "vuelidate/lib/validators";
    import Advertising from '@/services/Advertising';
    import Swal from "sweetalert2";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    //import Aws from '@/services/Aws'
    import validationMessages from '@/components/validations'

    export default {
        components:{
            flatPickr,
            validationMessages
        },
        props: {
            data: {
                type: Object,
                required: true
            },
            link_id: {
                type: String,
                default: null
            },
        },
        watch: {
            data: function(newVal) {
                this.title_data = newVal.title;
                this.link_data = newVal.link;
            }
        },
        data() {
            return {
                title_data: '',
                link_data: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                datetimeconfig: {
                    enableTime: true,
                    time_24hr: true
                },
                file: '',
                csrf_token: localStorage.getItem('csrf_token')
            };
        },
        validations: {
            title_data: {
                required,
                minLength: minLength(5)
            },
            link_data: {
                required
            },
        },
        methods: {
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },
            async editRssAdvertorial() {
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {

                        let formData = new FormData()
                        formData.append('source', process.env.VUE_APP_TENANT)
                        formData.append('link_id', this.link_id)
                        formData.append('title', this.title_data)
                        formData.append('link', this.link_data)
                        formData.append('image', this.data.image)
                        formData.append('file', this.file)
                        formData.append('content', this.data.content)
                        formData.append('app_position', this.data.app_position)
                        formData.append('published', this.data.published)
                        formData.append('date_from', this.data.date_from)
                        formData.append('date_to', this.data.date_to)
                        formData.append('_method', 'PATCH')
                        formData.append('csrf_token', this.csrf_token)


                        await Advertising.editRssAdvertorial(formData)
                        .then((response) => {
                            this.successmsg(response);
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })

                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            selectFile(event){
                this.file = event.target.files[0]
                this.data.image = URL.createObjectURL(this.file)

                // const fileName = event.target.files[0].name
                // const file = event.target.files[0]
                // const aws_folder = process.env.VUE_APP_AWS_S3_IMG_FOLDER + 'advertorials/'
                // if(file) {
                //     Aws.singleUpload(process.env.VUE_APP_AWS_S3_BUCKET, aws_folder, fileName, file)
                //         .then((response) => {
                //             this.data.image = response.fullPath
                //         })
                // }
            },

            closeModal() {
                this.showModal = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },

        }
    };


</script>



<template>
    <b-modal v-model="showModal" id="advertorials_edit" title="Edit Advertorial" title-class="font-18" centered>
        <form @submit.prevent="editAdvertorial">
            <b-form-group label="Title" label-for="title" class="mb-3">
                <b-form-input
                        v-model.trim="title_data"
                        type="text"
                        id="title"
                        aria-describedby="title-feedback"
                        :class="{
                          'is-invalid': submitted && $v.title_data.$error,
                        }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Title'" :validationName="$v.title_data"></validationMessages>
            </b-form-group>

            <b-form-group label="Advertorial URL" label-for="link" class="mb-3">
                <b-form-input
                        v-model="link_data"
                        type="text"
                        id="link"
                        aria-describedby="link-feedback"
                        :class="{
                          'is-invalid': submitted && $v.link_data.$error,
                        }"
                ></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Advertorial URL'" :validationName="$v.link_data"></validationMessages>
            </b-form-group>

            <b-form-group label="Image Link" label-for="image" class="mb-3">
                <img v-if="data.image" :src="data.image"  width="200" class="img-thumbnail me-2" style="max-height: 150px;" />
                <img v-else  width="200" src="@/assets/images/image_not_found.png" class="img-thumbnail me-2" style="max-height: 150px;" />
                <b-form-file @change="selectFile"  type="file" class="mt-3" plain accept="image/jpeg, image/png" ></b-form-file>
            </b-form-group>

            <b-form-group label="Content" label-for="content" class="mb-3">
                <b-form-textarea id="content" v-model="data.content" placeholder="Enter content..." rows="3" max-rows="6"></b-form-textarea>
            </b-form-group>

            <b-form-group label="App Position" label-for="app_position" class="mb-3">
                <b-form-input id="app_position" v-model="data.app_position" type="number" name="app_position" min="1" max="10"></b-form-input>
            </b-form-group>

            <label class="form-label mb-2">Published</label>
            <flat-pickr
                    v-model="data.published"
                    :config="datetimeconfig"
                    class="form-control mb-2"
                    name="published"
            />

            <label class="form-label mb-2">Date From</label>
            <flat-pickr
                    v-model="data.date_from"
                    :config="datetimeconfig"
                    class="form-control mb-2"
                    name="date_from"
            />

            <label class="form-label mb-2">Date To</label>
            <flat-pickr
                    v-model="data.date_to"
                    :config="datetimeconfig"
                    class="form-control mb-2"
                    name="date_to"
            />
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editRssAdvertorial" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change</b-button>
        </template>
    </b-modal>
</template>