<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Advertising from '@/services/Advertising'
    import modalEditAdvertorial from "@/components/modals/advertising/modalEditAdvertorial";

    export default {
        components: { Layout, PageHeader, modalEditAdvertorial},
        page: {
            title: "Advertorials",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "Advertorials",
                items: [
                    {
                        text: "Advertising",
                    },
                    {
                        text: "Advertorials",
                        active: true,
                        href: "/advertising/advertorial",
                    },
                ],
                isBusy: false,
                error: null,
                showModal:false,
                modalData:{},
                link_id:null,
                tableData: [],
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "age",
                sortDesc: false,
                fields: [
                    {
                        key: "title",
                        label: "Title",
                        sortable: true,
                    },
                    {
                        key: "link",
                        label: "Link",
                        sortable: false,
                    },
                    {
                        key: "image",
                        label: "Image",
                        sortable: false,
                    },
                    {
                        key: "content",
                        label: "Content",
                        sortable: false,
                    },
                    {
                        key: "app_position",
                        label: "App Position",
                        sortable: false,
                    },
                    {
                        key: "published",
                        label: "Published Date",
                        sortable: true,
                    },
                    {
                        key: "date_from",
                        label: "Date From",
                        sortable: true,
                    },
                    {
                        key: "date_to",
                        label: "Date To",
                        sortable: true,
                    },
                    "action",
                ],

            };
        },
        async created() {
           this.getAdvertorials()
        },
        methods: {
            async getAdvertorials(){
                try {
                    this.toggleBusy();
                    const response = await Advertising.getRssAdvertorial()
                    this.tableData = response.data.data
                    this.totalRows = this.tableData.length
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                    this.totalRows = 0
                }
            },
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            callModalAdvertorialsEdit(data) {
                this.$bvModal.show("advertorials_edit");
                this.modalData = data;
                this.link_id = data.link;
            },
        },
        middleware: "authentication",
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

            <div class="row">
                <div class="col-12">
                    <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Total Advertorials: {{totalRows}}</h4>
                        <div class="row mt-4">
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center">
                                Show&nbsp;
                                <b-form-select
                                v-model="perPage"
                                size="sm"
                                :options="pageOptions"
                                ></b-form-select
                                >&nbsp;entries
                            </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div
                            id="tickets-table_filter"
                            class="dataTables_filter text-md-end"
                            >
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <b-form-input
                                v-model="filter"
                                type="search"
                                placeholder="Search..."
                                class="form-control form-control-sm ms-2"
                                ></b-form-input>
                            </label>
                            </div>
                        </div>
                        <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                        <b-table
                            :busy="isBusy"
                            :items="tableData"
                            :fields="fields"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            show-empty
                            empty-text="No Data Found"
                            @filtered="onFiltered"
                        >

                            <template v-slot:cell(link)="data">
                                <a :href="data.item.link" target="_blank">{{data.item.link}}</a>
                            </template> 

                            <template v-slot:cell(image)="data">
                                <img class="img-fluid" :src="data.item.image" width="100">
                            </template> 

                            <template v-slot:cell(action)="data">
                                <ul class="list-inline mb-0">
                                    <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-primary" v-b-tooltip.hover title="Edit" @click="callModalAdvertorialsEdit(data.item)">
                                            <i class="uil uil-pen font-size-18"></i>
                                        </a>
                                    </li>
                                </ul>
                            </template>

                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </template>
                        
                        </b-table>
                        </div>
                        <div class="row">
                        <div class="col">
                            <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                            >
                            <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                ></b-pagination>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

                <!-- MODALS --> 
    <modalEditAdvertorial :data="modalData" :link_id="link_id" @onRefresh="getAdvertorials">
    </modalEditAdvertorial>
    
    <!-- END  MODALS --> 
    </Layout>
</template>